<script setup lang="ts">
import type { AvailablePaymentType } from '~/types/bookingPayment'

interface Props {
  /**
   * Set the `id` attribute of the radio input
   */
  inputId: string
  /**
   * Set the `value` attribute of the input
   */
  value: AvailablePaymentType
}

const modelValue = defineModel<AvailablePaymentType | null>({ required: false, default: null })

const props = defineProps<Props>()

const isSelected = computed(() => {
  return modelValue.value === props.value
})

onMounted(() => {
  // Sometimes when reloading the page the browser will check the radio input (based on the last time the form was submitted)*
  // but the modelValue is still at null. This make sure it's synced.
  const input = document.getElementById(props.inputId)
  if (input instanceof HTMLInputElement && input.checked) {
    modelValue.value = props.value
  }
})
</script>

<template>
  <div
    class="flex flex-col items-stretch rounded-sm transition-all duration-200 overflow-hidden"
    :class="{
      'ring-1 ring-gray-300': !isSelected,
      'ring-4 ring-pink-500': isSelected,
    }"
  >
    <label
      class="flex px-6 items-center justify-between cursor-pointer py-3"
      :class="{ 'border-b border-gray-300 pl-5': isSelected || $slots['additional-information'] }"
      :for="inputId"
    >
      <YscInputRadio
        :id="inputId"
        v-model="modelValue"
        :value="value"
        name="payement_method"
        class="flex-1 items-center"
        :class="{ 'py-4': !isSelected, 'py-5': isSelected }"
        is-block
        required
      >
        <!-- @slot Name of the payment method -->
        <slot name="label" />
      </YscInputRadio>
      <div
        v-if="$slots.logos"
        class="flex items-center gap-1.5"
      >
        <!-- @slot logo associated to the payment method, displayed inline with the label -->
        <slot name="logos" />
      </div>
    </label>
    <template v-if="$slots['additional-information']">
      <slot name="additional-information" />
    </template>
    <template v-if="isSelected">
      <!-- @slot Displayed when the method of payment is selected -->
      <slot name="expanded" />
    </template>
  </div>
</template>
