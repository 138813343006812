/**
 * Implementation of Luhn algorithm
 * @see  https://en.wikipedia.org/wiki/Luhn_algorithm
 * @param {string|null} cardNumber
 * @returns {boolean}
 */
const validateCheckDigit = (cardNumber: string | null): boolean => {
  if (!cardNumber) {
    return false
  }

  let nCheck = 0,
    bEven = false
  const value = cardNumber.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n)
    let nDigit = parseInt(cDigit, 10)
    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9
      }
    }
    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

// Ported from https://github.com/Mangopay/cardregistration-js-kit
export const validateCardNumber = (cardNumber: string): boolean => {
  // Validate string contains only numeric
  if (!cardNumber.match(/^[0-9]+$/)) {
    return false
  }

  // Luhn algorith
  return validateCheckDigit(cardNumber)
}
